//
window.addEventListener('load', () => {
  // SWITCHING AREAMAPS
  function changemaps($area) {
    console.log($area);

    switch ($area) {
      case 'south':
        document.getElementById('select-maps').classList.add('south')
        document.getElementById('map-detail-south').classList.add('standby')
        document.getElementsByClassName('counts-all')[0].classList.remove('active')
        document.getElementsByClassName('counts-nanbu')[0].classList.add('active')
        setTimeout(() => {
          document.getElementById('map-detail-south').classList.add('active')
          document.getElementsByClassName('select-area')[0].children[2].classList.add('active')
        }, 200);
        break;

      case 'chubu':
        document.getElementById('select-maps').classList.add('chubu')
        document.getElementById('map-detail-chubu').classList.add('standby')
        document.getElementsByClassName('counts-all')[0].classList.remove('active')
        document.getElementsByClassName('counts-chubu')[0].classList.add('active')
        setTimeout(() => {
          document.getElementById('map-detail-chubu').classList.add('active')
          document.getElementsByClassName('select-area')[0].children[1].classList.add('active')
        }, 200);
        break;

      case 'north':
        document.getElementById('select-maps').classList.add('north')
        document.getElementById('map-detail-north').classList.add('standby')
        document.getElementsByClassName('counts-all')[0].classList.remove('active')
        document.getElementsByClassName('counts-north')[0].classList.add('active')
        setTimeout(() => {
          document.getElementById('map-detail-north').classList.add('active')
          document.getElementsByClassName('select-area')[0].children[0].classList.add('active')
        }, 200);
        break;

      default:
        break;
    }
  } //end function

  function hide_area_btn() {
    document.getElementsByClassName('label_php_north')[0].classList.remove('active')
    document.getElementsByClassName('label_php_center')[0].classList.remove('active')
    document.getElementsByClassName('label_php_south')[0].classList.remove('active')
    document.getElementsByClassName('select-area')[0].classList.add('active')

    setTimeout(() => {
      document.getElementsByClassName('label_php_north')[0].classList.add('hidden')
      document.getElementsByClassName('label_php_center')[0].classList.add('hidden')
      document.getElementsByClassName('label_php_south')[0].classList.add('hidden')
    }, 400);
  }

  if (document.getElementsByClassName('label_php_south')[0]) {
    document.getElementsByClassName('label_php_south')[0].onclick = () => {
      changemaps('south');
      hide_area_btn();
    }
    document.getElementsByClassName('label_php_center')[0].onclick = () => {
      changemaps('chubu');
      hide_area_btn();
    }
    document.getElementsByClassName('label_php_north')[0].onclick = () => {
      changemaps('north');
      hide_area_btn();
    }
    document.getElementsByClassName('nanbu')[0].onclick = () => {
      changemaps('south');
      hide_area_btn();
    }
    document.getElementsByClassName('chubu')[0].onclick = () => {
      changemaps('chubu');
      hide_area_btn();
    }
    document.getElementsByClassName('hokubu')[0].onclick = () => {
      changemaps('north');
      hide_area_btn();
    }

    // COLOR BUTTON
    document.getElementsByClassName('js_north')[0].onclick = () => {
      changemaps('north');
      hide_area_btn();
    }
    document.getElementsByClassName('js_center')[0].onclick = () => {
      changemaps('chubu');
      hide_area_btn();
    }
    document.getElementsByClassName('js_south')[0].onclick = () => {
      changemaps('south');
      hide_area_btn();
    }
  } //end if

  $selected_area = location.pathname;
  function delete_label() {
    document.getElementsByClassName('label_php_north')[0].classList.remove('active')
    document.getElementsByClassName('label_php_center')[0].classList.remove('active')
    document.getElementsByClassName('label_php_south')[0].classList.remove('active')
    document.getElementById('select-maps').classList.remove('standby')
    document.getElementById('select-maps').classList.remove('active')
  } //end function

  if ($selected_area == '/select_area') {
    document.getElementsByClassName('select-area')[0].classList.add('active')
    document.getElementById('maps-inner-all').classList.add('selected')
    delete_label()
    document.getElementsByClassName('label_php_north')[0].classList.add('hidden')
    document.getElementsByClassName('label_php_center')[0].classList.add('hidden')
    document.getElementsByClassName('label_php_south')[0].classList.add('hidden')
  }



  // END SWITCHING AREAMAPS.

}) //end event load
